import { includes, isArray, isNil } from "lodash";

// ------------------------------------------------------------------------------------------
// ---------------- PROCESSES ---------------------------------------------------------------
// ------------------------------------------------------------------------------------------

// APOLLO
export const APOLLO_FRONT_ONLY_IDENTIFIER = "apollo";
export const APOLLO_PROCESS_PARAM = "tasks";
export const LABELLING_PROCESS_PARAM = "labelling";

// AVIS
export const AVIS_FRONT_ONLY_IDENTIFIER = "avis";
export const AVIS_LOCAL_PROCESS_PARAM = "local";
export const AVIS_ZONE_PROCESS_PARAM = "zone";

// ONEPRICE
export const ONEPRICE_FRONT_ONLY_IDENTIFIER = "oneprice";
export const ONEPRICE_PROCESS_PARAM = "pricing";

// FRIDAY
export const FRIDAY_FRONT_ONLY_IDENTIFIER = "friday";
export const FRIDAY_PROCESS_PARAM = "pedidos";
export const DROP_PROCESS_PARAM = "drop";

// FENIX
export const FENIX_FRONT_ONLY_IDENTIFIER = "fenix";
export const FENIX_PROCESS_PARAM = "inspeccionmuestras";

export const PROCESS_LEVEL_DASHBOARD = "dashboard";
export const PROCESS_LEVEL_LIST = "list";
export const PROCESS_LEVEL_HISTORY = "history";
export const PROCESS_LEVEL_FORMS = "forms";

export const HISTORY_NOT_NEW_FORM_PREFIX = "history/form/";

export const ROW_DISABLED = "N";

export const GENERIC_ALL = "ALL";
export const GENERIC_EMPTY = "EMPTY";

// scope filters
export const FILTER_SCOPE_OWN = "OWN";
export const FILTER_SCOPE_OTHERS = "OTHERS";
export const FILTER_SCOPE_ALL = "ALL";

// status filters
export const FILTER_STATUS_PENDING = "PENDING";
export const FILTER_STATUS_FINISED = "FINISHED";
export const FILTER_STATUS_ALL = "ALL";

export const filterOwnerData = [
  { value: FILTER_SCOPE_OWN, label: "GEN_DASH_FILTER_OWN" },
  { value: FILTER_SCOPE_OTHERS, label: "GEN_DASH_FILTER_OTHERS" },
  { value: FILTER_SCOPE_ALL, label: "GEN_DASH_FILTER_ALL" },
];

export const filterStatusData = [
  { value: FILTER_STATUS_PENDING, label: "APOLLO_DASH_FILTER_PENDING" },
  { value: FILTER_STATUS_FINISED, label: "APOLLO_DASH_FILTER_FINISHED" },
  { value: FILTER_STATUS_ALL, label: "GEN_DASH_FILTER_ALL" },
];

// countries filters name
export const COUNTRIES_FILTER_NAME = "countries";
export const CLUSERTS_FILTER_NAME = "clusters";
export const HUBS_FILTER_NAME = "hubs";
export const APOLLO_FILTERS_MATS_SPECS = "matSpec";
export const ENTREGAS_FILTER_NAME = "entregas";

export const ALCANCE_CLUSTER = "CL";
export const ALCANCE_HUB = "HU";
export const ALCANCE_PAIS = "PA";

export const DEFAULT_PAGE_NUMBER_DASHBOARD = 1;
export const DEFAULT_PAGE_SIZE_DASHBOARD = 50;
export const DEFAULT_SORT_BY_DASHBOARD = "-";
export const DEFAULT_SORT_DIR_DASHBOARD = "-";
export const DEFAULT_SEARCH_DASHBOARD = "-";

export const SHOW_TIME_STATUS_COL_NAME = "timeStatus";
export const PROCESS_ID_COL_NAME = "processId";
export const GROUPED_TASK_COL_NAME = "groupedTask";

export const OPEN_QUERY_DEF_COL_NAME = "openQueryDef";
export const OPEN_FORM_DEF_COL_NAME = "openFormDef";

export const DEFAULT_DB_FIELD_PLACEHOLDER = "xx";

export const resolveRouteNameByLevel = ({ process, level }) => {
  let result = "";
  switch (level) {
    case PROCESS_LEVEL_DASHBOARD:
      result = resolveDashboardNameByProcess({ process });
      break;
    case PROCESS_LEVEL_LIST:
      result = resolveListNameByProcess({ process });
      break;
    case PROCESS_LEVEL_FORMS:
      result = resolveFormNameByProcess({ process });
      break;
    case PROCESS_LEVEL_HISTORY:
      result = resolveHistoryNameByProcess({ process });
      break;
    default:
      break;
  }
  return result;
};

export const resolveDashboardNameByProcess = ({ process }) => {
  let result = "";
  switch (process) {
    case AVIS_LOCAL_PROCESS_PARAM:
      result = "AVIS_DASH_ROUTE_NAME";
      break;
    case ONEPRICE_PROCESS_PARAM:
      result = "ONEPRICE_DASH_ROUTE_NAME";
      break;
    default:
      break;
  }
  return result;
};

export const resolveListNameByProcess = ({ process }) => {
  let result = "";
  switch (process) {
    case AVIS_LOCAL_PROCESS_PARAM:
      result = "AVIS_LIST_ROUTE_NAME";
      break;
    case ONEPRICE_PROCESS_PARAM:
      result = "ONEPRICE_LIST_ROUTE_NAME";
      break;
    default:
      break;
  }
  return result;
};

export const resolveHistoryNameByProcess = ({ process }) => {
  let result = "";
  switch (process) {
    case AVIS_LOCAL_PROCESS_PARAM:
      result = "AVIS_HISTORY_ROUTE_NAME";
      break;
    case ONEPRICE_PROCESS_PARAM:
      result = "ONEPRICE_HISTORY_ROUTE_NAME";
      break;
    default:
      break;
  }
  return result;
};

export const resolveFormNameByProcess = ({ process }) => {
  let result = "";
  switch (process) {
    case AVIS_LOCAL_PROCESS_PARAM:
      result = "AVIS_FORM_ROUTE_NAME";
      break;
    case ONEPRICE_PROCESS_PARAM:
      result = "ONEPRICE_FORM_ROUTE_NAME";
      break;
    default:
      break;
  }
  return result;
};

export function showItemOnProcess({ process, allowedProcessArray }) {
  if (
    isNil(process) ||
    isNil(allowedProcessArray) ||
    !isArray(allowedProcessArray)
  ) {
    return true;
  }

  return includes(allowedProcessArray, process);
}

export function getLabelTranslationByProcessAndCard(process, card) {
  if (process === FRIDAY_PROCESS_PARAM || process === DROP_PROCESS_PARAM) {
    if (card === "PAS") {
      return "APOLLO_DASH_LIST_COUNTER_MATS";
    } else {
      return "FRIDAY_DASH_CARDS_LABEL";
    }
  } else if (process === FENIX_PROCESS_PARAM) {
    if (card === "EM" || card === "RM") {
      return "Entregas";
    } else {
      return "APOLLO_DASH_LIST_COUNTER_MATS";
    }
  } else {
    if (card === "SR" || card === "CS") {
      return "APOLLO_DASH_LIST_COUNTER_MATS";
    } else {
      return "APOLLO_DASH_LIST_COUNTER_REQS";
    }
  }
}

export function getDefaultPageSize(process) {
  if (process === FRIDAY_PROCESS_PARAM || process === DROP_PROCESS_PARAM) {
    return 100;
  } else {
    return DEFAULT_PAGE_SIZE_DASHBOARD;
  }
}
