import {
  fetchInitialInfoApi,
  fetchPreCheckoutInfoApi,
} from "../../api/infoApi";

const INITIAL_STATE = {
  // initial info
  loadingInfo: false,
  errorLoadingInfo: null,
  initialInfo: null,
  firstInitialInfoCompleted: false,
  showClosedEmsDialog: false,
  showDialogNoActiveCatalog: false,

  // pre checkout dialog
  preCheckoutInfo: {
    showPreCheckoutConfirmationDialog: false,
    preCheckoutConfirmationDialogTitle: null,
    preCheckoutConfirmationDialogBodyItems: null,
  },
};

const createInfoSlice = (set) => ({
  ...INITIAL_STATE,

  fetchInitialInfo: async (headers, logout) => {
    set({ loadingInfo: true, errorLoadingInfo: null });
    try {
      const { data, error } = await fetchInitialInfoApi(headers, logout);
      if (data) {
        set({
          initialInfo: data,
          loadingInfo: false,
          firstInitialInfoCompleted: true,
          showClosedEmsDialog: data?.isEmsClosed,
        });
      } else {
        set({
          errorLoadingInfo: error,
          loadingInfo: false,
          firstInitialInfoCompleted: true,
        });
      }
    } catch (error) {
      set({
        errorLoadingInfo: error.message,
        loadingInfo: false,
        firstInitialInfoCompleted: true,
      });
    }
  },

  openClosedEmsDialog: () => {
    set({ showClosedEmsDialog: true });
  },
  closeClosedEmsDialog: () => {
    set({ showClosedEmsDialog: false });
  },

  openNoActiveCatalogDialog: () => {
    set({ showDialogNoActiveCatalog: true });
  },
  closeNoActiveCatalogDialog: () => {
    set({ showDialogNoActiveCatalog: false });
  },
  fetchPreCheckoutInfo: async (headers, logout) => {
    try {
      const { data } = await fetchPreCheckoutInfoApi(headers, logout);
      if (data) {
        set({
          preCheckoutInfo: data,
        });
        return data;
      } else {
        set({
          preCheckoutInfo: INITIAL_STATE.preCheckoutInfo,
        });
        return null;
      }
    } catch (error) {
      set({
        preCheckoutInfo: INITIAL_STATE.preCheckoutInfo,
      });
      return null;
    }
  },
  closePreCheckoutConfirmationDialog: () => {
    set({ preCheckoutInfo: INITIAL_STATE.preCheckoutInfo });
  },

  // ALWAYS AT THE END FOR READABILITY
  resetInfoSlice: () => {
    set({
      INITIAL_STATE,
    });
  },
});

export default createInfoSlice;
