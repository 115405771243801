import React, {
  useState,
  useEffect,
  useContext,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { Responsive as ResponsiveGridLayout } from "react-grid-layout";
import { useTheme } from "@material-ui/core/styles";
import {
  //Badge,
  Icon,
  Tooltip,
  Tab,
  Tabs,
  Divider,
  CircularProgress,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@material-ui/core";
import ArrowBack from "@material-ui/icons/ArrowBack";
import Form from "@rjsf/core";
import { format } from "date-fns";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  includes,
  isBoolean,
  //clone,
  isEmpty,
  isFunction,
  isNil,
  isString,
  startsWith,
  toLower,
  trim,
  isMap,
  get,
  filter,
} from "lodash";
import { SizeMe } from "react-sizeme";
import BlockUi from "react-block-ui";
//import { Prompt, useRouteMatch } from "react-router-dom";

import NothingToShow from "../../nothingToShow/NothingToShow";
import DeleteFormConfirmDialog from "../../dialogs/deleteFormConfirm/DeleteFormConfirmDialog";
import BadForm from "../../badForm/BadForm";
import {
  callServerToSetValue,
  confirmForm,
  //controlHasError,
  extractErrorMessageFromResponse,
  //getErrorList,
  getHTTPMethodByOperaton,
  isServerResponseValid,
  makeErrorMapToSet,
  makeUniqueFormID,
  resolveFieldAgainstShadowStatus,
  returnFocusToRef,
  transformFormStatusFromServer,
  valueHasChanged,
} from "../../../util/UtilForm";
import {
  BLOCK_TYPE_LIST,
  BLOCK_TYPE_SIMPLE,
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  DATE_TIME_PATTERN,
  FORM_OPERATION_DELETE,
  FORM_OPERATION_NEW,
  FORM_OPERATION_VIEW,
  FORM_OPERATON_EDIT,
  METHOD_PUT,
  TOAST_CONTAINER_LAYOUT,
  TOAST_CONTAINER_FORM_CONTAINER,
  TOAST_TYPE_ERROR,
  HTTP_STATUS_UNAUTHORIZED,
  HTTP_STATUS_NOT_FOUND,
  IS_FORM_BUILDING_HELP_ENABLED,
} from "../../../util/Constants";
import { AuthContext } from "../../../core/providers/AuthContext";
import { customSimpleFields } from "../../../components/forms/customSimpleFields/fields";
import useWindowDimensions from "../../../core/hooks/useWindowDimensions";
import GridTable from "../customTable/container/GridTableCustom";
import { DeviceContext } from "../../../core/providers/DeviceContext";
import { DrawerContext } from "../../../core/providers/DrawerContext";
import { LayoutContext } from "../../../core/providers/LayoutContext";
import SimpleBlock from "../blocks/simple/SimpleBlock";

import useExternalForm from "../../../core/hooks/useExternalForm.js";
import {
  useStyles,
  commonIcon,
  backButton,
  FOOTER_MIN_HEIGHT,
} from "./BaseFormStyles";
import { useRequestHeaders } from "../../../core/hooks/useRequestHeaders";
import { useBlockingToast } from "../../../core/hooks/useBlockingToast";
import {} from "../formContainer/FormContainer";
import CloseSubTabDialog from "../../../shared/layout/components/tabs/dialogs/CloseSubTabDialog";
import TrackingServicesDialog from "../../dialogs/trackingServicesDialog/TrackingServicesDialog";
import { KEY_T } from "../../../util/UtilKeys";
import HelpDialog from "../../dialogs/helpDialog/HelpDialog";
import GenericToastContainer from "../../toast/GenericToastContainer.js";
import useWizardStore from "../../../core/stores/WizardStore.js";
import { BadgeWizardStepSection } from "../blocks/simple/BadgeWizardStepSection.js";

const IS_DEV = process.env.NODE_ENV === "development";

export const EXERNAL_ID_FORM_CONTAINER_ACTION_BUTTON =
  "external_form_container_action_button";

export default function BaseForm(props) {
  const toastId = useRef(null);

  const {
    ukey,
    operation,
    path,
    idForm,
    handleBack,
    handleBackOnDelete,
    values,
    overrideOps,
    process,
    hideTitle,
    setSyncFormInstanceId,
    solution,
    tableName,
    openFormLikeRecordsPanel,
    keepField,
  } = props;

  const { auth, logout } = useContext(AuthContext);
  const { changeTextHeader, changeLayoutBreakpoint } =
    useContext(LayoutContext);
  const { deviceInfo } = useContext(DeviceContext);
  const { drawerOpen } = useContext(DrawerContext);
  const { t } = useTranslation();
  const wd = useWindowDimensions();
  const theme = useTheme();
  const classes = useStyles({ wd, openDrawer: drawerOpen });
  const {
    initialFormData,
    loading,
    formSchema,
    formInstanceId,
    gridLayout,
    formData,
    setFormData,
    actualOperation,
    changeOperation,
    getDescText,
    getFasIcon,
    getOperationText,
    selectedTab,
    changeFormTab,
    //executedAction,
    setExecutedAction,
    shadowStatus,
    updateShadowStatus,
    jasperReports,
    handleJasperReport,
    handleJasperOption,
    handleCloseJasperOptions,
    anchorElJasperOpt,
    generatingJasper,
    moveToAnotherSectionByIndex,
  } = useExternalForm({
    ukey,
    path,
    idForm,
    values,
    operation,
    process,
    solution,
    tableName,
    openFormLikeRecordsPanel,
  });

  const { shouldRenderBadge, removeBadge, disabledBlocks } = useWizardStore();

  const arrayBlocksDisabledByWizard =
    !isNil(disabledBlocks) &&
    !isEmpty(disabledBlocks) &&
    isMap(disabledBlocks) &&
    disabledBlocks.has(formInstanceId)
      ? disabledBlocks.get(formInstanceId)
      : [];

  const [alreadyDone, isAlreadyDone] = useState(false);
  const [fetching, isFetching] = useState(false);
  const [errorMap, setErrorMap] = useState([]);
  const [deleteFormConfirmation, setDeleteFormConfirmation] = useState(false);
  const [isSettingValueInServer, setIsSettingValueInServer] = useState(false);
  const [sortedSections, setSortedSections] = useState([]);
  const [showCloseFormDialogBase, setShowCloseFormDialogBase] = useState(false);
  const [showTrazability, setShowTrazability] = useState(false);
  const [openTSDialog, setOpenTSDialog] = useState(false);

  const REQUEST_HEADERS = useRequestHeaders();

  const { renderBlockingToast, BlockingToastModal } = useBlockingToast();

  let formOperations = formSchema?.defaultPanelConfig?.formOperations || [];

  const formHasChanged =
    !isNil(formData) &&
    !isNil(formData.formChanged) &&
    formData.formChanged === true;

  //let errorListToShow = getErrorList(errorMap);

  useEffect(() => {
    if (!isNil(formSchema) && !isNil(formSchema.sections)) {
      const sortedtoAdd = formSchema.sections.sort(
        (a, b) => a.position - b.position
      );

      setSortedSections(sortedtoAdd);
      const textHeaderMade =
        !isNil(formSchema?.name) && formSchema?.name !== ""
          ? formSchema?.name + " (" + getOperationText() + ")"
          : "";

      changeTextHeader(textHeaderMade);
    }

    return () => null;
    // eslint-disable-next-line
  }, [formSchema]);

  useEffect(() => {
    if (isFunction(setSyncFormInstanceId)) {
      setSyncFormInstanceId(formInstanceId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formInstanceId]);

  useEffect(() => {
    const handler = (e) => {
      const { keyCode, altKey } = e;

      if (altKey && keyCode === KEY_T) {
        e.preventDefault();
        setShowTrazability((show) => !show);
      }
    };

    window.addEventListener("keydown", handler);

    return () => {
      window.removeEventListener("keydown", handler);
    };
  }, []);

  function handleTSDialog(open) {
    setOpenTSDialog(open);
  }

  //In charge of notify fields value to server ONLY SIMPLE FIELDS (grid fields are managed by GridContainer)
  const notifySetValueToServer = async (
    formInstanceId,
    completeIdControl,
    fieldValue,
    line,
    controlRef
  ) => {
    if (
      valueHasChanged(shadowStatus, {
        field: completeIdControl,
        newValue: fieldValue,
        line,
      })
    ) {
      setIsSettingValueInServer(true);

      //Await for server response
      const response = await callServerToSetValue(
        formInstanceId,
        completeIdControl,
        fieldValue,
        line,
        REQUEST_HEADERS
      );
      //Make toast content
      let toastContent = null;

      //Process response
      if (response && response.status === HTTP_STATUS_UNAUTHORIZED) {
        logout(true);
      } else if (response && response.status === HTTP_STATUS_NOT_FOUND) {
        toastContent = {
          title: `${t("ERROR_RESOURCE_NOT_FOUND_TEXT")}`,
        };
      } else if (isServerResponseValid(response)) {
        transformStatusAndSetInState(response, completeIdControl);
      } else if (
        !isNil(response) &&
        !isNil(response.error) &&
        response.error === true &&
        !isNil(response.msg)
      ) {
        transformStatusAndSetInState(response, null);

        //Extract error message if there is one
        const errorText = extractErrorMessageFromResponse(response);

        //Make new error map adding this one for the control
        const newErroMap = makeErrorMapToSet(
          errorMap,
          completeIdControl,
          errorText
        );

        //Set new error map with the control ones updated with this error
        syncErrorMap(newErroMap);

        toastContent = {
          title: errorText,
        };

        returnFocusToRef(controlRef);
      }

      //Throw notification
      if (toastContent !== null) {
        renderBlockingToast({
          type: TOAST_TYPE_ERROR,
          ...toastContent,
        });
      }

      setIsSettingValueInServer(false);
    }
  };

  function transformStatusAndSetInState(response, completeIdControl) {
    //Destr fstatus
    const { fstatus } = response;

    //update shadow status instantly
    updateShadowStatus(fstatus);

    const transformedFormStatus = transformFormStatusFromServer(fstatus);
    const newGlobalData = { ...formData, ...transformedFormStatus };

    // if (completeIdControl) {
    //   //Check if control has errors to clear them
    //   checkToClearErrorMsg(completeIdControl);
    // }

    //Sync state
    handleFormDataChange(newGlobalData);
  }

  //Check in error map if some error is assosciated with completeIdControl
  //If it has, remove toast and sync map
  // const checkToClearErrorMsg = (completeIdControl) => {
  //   const controlWithError = controlHasError(errorMap, completeIdControl);
  //   if (controlWithError) {
  //     let newMap = clone(errorMap);
  //     newMap = newMap.filter(function (obj) {
  //       return obj.key !== completeIdControl;
  //     });
  //     //Set new error map without control ones
  //     setErrorMap(newMap);
  //     //Dismiss possible notifications of this control
  //     toast.dismiss(completeIdControl);
  //   }
  // };

  function handleExecuteAction() {
    if (actualOperation === FORM_OPERATION_DELETE) {
      setDeleteFormConfirmation(true);
    } else {
      executeAction();
    }
  }

  //Execute action by operation NEW, EDIT, DELETE
  async function executeAction() {
    let quitFetching = true;
    isFetching(true);
    const method = getHTTPMethodByOperaton(actualOperation);

    const audit = {
      ...deviceInfo,
      userName: auth?.userName,
      clientDateMod: format(new Date(), DATE_TIME_PATTERN),
      operation: actualOperation,
    };

    const result = await confirmForm(
      { formInstanceId, audit },
      REQUEST_HEADERS
    );

    if (result && result.ok) {
      setExecutedAction(true);
      const toastText = t("FORM_SUCCESS_ACTION");

      toastId.current = toast.success(toastText, {
        containerId: TOAST_CONTAINER_LAYOUT,
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      if (
        actualOperation === FORM_OPERATION_DELETE &&
        !isNil(handleBackOnDelete) &&
        isFunction(handleBackOnDelete)
      ) {
        quitFetching = false;
        setTimeout(handleBackOnDelete, 1500);
      } else {
        quitFetching = false;
        if (!isNil(keepField)) {
          const keepFieldValue = resolveFieldAgainstShadowStatus({
            field: keepField,
            shadowStatus,
            completeIdControl: keepField,
          });
          handleBack({ keepFieldValue });
        } else {
          setTimeout(handleBack, 1500);
        }
      }
      //TODO: do something to close or block de form
    } else if (result && result.ok === false && result.msg) {
      renderBlockingToast({
        type: TOAST_TYPE_ERROR,
        title: result.msg,
      });
      // toastId.current = toast.error(result.msg, {
      //   containerId: TOAST_CONTAINER_FORM_CONTAINER,
      //   position: "top-right",
      //   autoClose: false,
      //   hideProgressBar: false,
      //   closeOnClick: false,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    } else {
      const toastText = t("FORM_ERROR_ACTION");
      renderBlockingToast({
        type: TOAST_TYPE_ERROR,
        msg: t("ERROR_RESOURCE_NOT_FOUND_TEXT"),
        title: toastText,
      });
      // toastId.current = toast.error(toastText, {
      //   containerId: TOAST_CONTAINER_FORM_CONTAINER,
      //   position: "top-right",
      //   autoClose: false,
      //   hideProgressBar: false,
      //   closeOnClick: false,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });
    }
    if (quitFetching) {
      isFetching(false);
    }
    if (!result && method === METHOD_PUT) isAlreadyDone(false);
  }

  //Send PURE form data object to update state
  function handleFormDataChange(formData) {
    setFormData(formData);
  }

  //Sync error map between inner components and fom container(here)
  function syncErrorMap(arrayOfErrorsToSync) {
    if (!isNil(arrayOfErrorsToSync)) {
      setErrorMap(arrayOfErrorsToSync);
    }
  }

  function isOutOperationOverride(operation) {
    if (
      !isNil(overrideOps) &&
      Array.isArray(overrideOps) &&
      !overrideOps.includes(operation)
    ) {
      return true;
    } else {
      return false;
    }
  }

  const toggleIsSettingValueToServer = useCallback((toggle) => {
    setIsSettingValueInServer(toggle);
  }, []);

  function handleBackClick() {
    if (formHasChanged) {
      setShowCloseFormDialogBase(true);
    } else {
      handleBack({ formInstanceId });
    }
  }

  function handleCloseSubTab(leave) {
    setShowCloseFormDialogBase(false);
    if (leave) handleBack({ formInstanceId });
  }

  const getValueInShadowStatus = useCallback(
    (field, completeIdControl) => {
      return resolveFieldAgainstShadowStatus({
        field,
        shadowStatus,
        completeIdControl,
      });
    },
    [shadowStatus]
  );

  const resolveTitleBlockWithFormStatus = useCallback(
    (blockTitle) => {
      if (
        !isNil(blockTitle) &&
        isString(blockTitle) &&
        trim(blockTitle) !== "" &&
        (startsWith(blockTitle, "#") || startsWith(blockTitle, "$"))
      ) {
        const valueInShadowStatus = getValueInShadowStatus(
          blockTitle,
          blockTitle
        );
        return !isNil(valueInShadowStatus) ? valueInShadowStatus : blockTitle;
      } else {
        return blockTitle;
      }
    },
    [getValueInShadowStatus]
  );

  const filteredGridLayoutItemsByVisibility = useMemo(() => {
    const blocks = formSchema?.sections?.[selectedTab]?.blocks;

    if (
      isNil(gridLayout) ||
      isEmpty(gridLayout) ||
      isNil(blocks) ||
      isEmpty(blocks) ||
      isNil(shadowStatus)
    ) {
      return [gridLayout, formSchema?.sections?.[selectedTab]?.blocks];
    }

    // Crear un objeto con los ids de los bloques visibles
    const visibleBlocks = filter(blocks, function (o) {
      return isBoolean(o?.isVisible)
        ? o?.isVisible
        : isString(o?.isVisible)
        ? toLower(getValueInShadowStatus(o?.isVisible, o?.isVisible)) === "s"
        : true;
    }).map((block) => block?.id);

    // Filtrar el gridLayout para cada breakpoint, asegurándose de que los accesos sean seguros
    const filteredGridLayout = Object.keys(gridLayout).reduce(
      (acc, breakpoint) => {
        const layout = get(gridLayout, breakpoint, []);
        acc[breakpoint] = layout.filter((item) =>
          visibleBlocks.includes(item.i)
        );
        return acc;
      },
      {}
    );

    return [filteredGridLayout, visibleBlocks];
  }, [
    formSchema,
    getValueInShadowStatus,
    gridLayout,
    selectedTab,
    shadowStatus,
  ]);

  return (
    <>
      <CloseSubTabDialog
        open={showCloseFormDialogBase}
        handleAction={handleCloseSubTab}
      />
      <GenericToastContainer containerId={TOAST_CONTAINER_FORM_CONTAINER} />
      <BlockingToastModal />
      {formInstanceId && openTSDialog && (
        <TrackingServicesDialog
          formInstanceId={formInstanceId}
          handleOpen={handleTSDialog}
          open={openTSDialog}
          requestHeaders={REQUEST_HEADERS}
        />
      )}
      <div className={classes.contentContainer}>
        {loading ? (
          <CircularProgress size={25} />
        ) : loading === false && isNil(formSchema) ? (
          <NothingToShow />
        ) : (
          <div className={classes.mainContainer}>
            {/* HEADER (Title + Close button) */}
            <div className={classes.header}>
              {/* START BUTTON GROUP TO CHANGE OPERATION FROM VIEW TO EDIT or  DELETE*/}
              {actualOperation !== FORM_OPERATION_NEW ? (
                <div className={classes.headerStartButtonGroup}>
                  {/* BACK button */}
                  <IconButton onClick={handleBackClick} style={backButton}>
                    <ArrowBack />
                  </IconButton>
                  {/*CHANGE TO VIEW BUTTON */}
                  {!isOutOperationOverride(FORM_OPERATION_VIEW) && (
                    <Tooltip title={t("TABLE_PANEL_DECISION_VIEW")}>
                      <button
                        disabled={
                          !isNil(overrideOps) &&
                          Array.isArray(overrideOps) &&
                          !overrideOps.includes(FORM_OPERATION_VIEW)
                        }
                        className={
                          !isNil(actualOperation) &&
                          actualOperation === FORM_OPERATION_VIEW
                            ? classes.editButtonCommonSelected
                            : classes.editButtonCommon
                        }
                        style={{ marginLeft: 0 }}
                        onClick={() => changeOperation(FORM_OPERATION_VIEW)}
                      >
                        <Icon
                          className="fas fa-eye"
                          style={{
                            fontSize: "1.2em",
                            color:
                              !isNil(actualOperation) &&
                              actualOperation === FORM_OPERATION_VIEW
                                ? "white"
                                : theme.palette.content.mainColor,
                            width: "auto",
                          }}
                        />
                      </button>
                    </Tooltip>
                  )}
                  {/* JASPER REPORTS EXPORTS */}
                  {actualOperation === FORM_OPERATION_VIEW &&
                    jasperReports.length > 0 && (
                      <div>
                        <Tooltip title={t("JASPER_BUTTON_LABEL")}>
                          <button
                            className={classes.editButtonCommon}
                            style={{ marginLeft: 0 }}
                            onClick={(e) => {
                              if (jasperReports.length === 1) {
                                handleJasperReport(jasperReports[0]);
                              } else {
                                handleJasperOption(e);
                              }
                            }}
                            id={"form_container_jasper_button"}
                          >
                            {generatingJasper ? (
                              <CircularProgress size={20} />
                            ) : (
                              <Icon
                                className="fas fa-print"
                                style={{
                                  fontSize: "1.2em",
                                  color: theme.palette.content.mainColor,
                                  width: "auto",
                                }}
                              />
                            )}
                          </button>
                        </Tooltip>
                        {jasperReports.length > 1 && !generatingJasper && (
                          <Menu
                            id="jasper-reports-options"
                            anchorEl={anchorElJasperOpt}
                            keepMounted
                            open={Boolean(anchorElJasperOpt)}
                            onClose={handleCloseJasperOptions}
                          >
                            {jasperReports.map((jasper, index) => (
                              <MenuItem
                                key={`${jasper?.resultReportName}_${index}`}
                                onClick={() => handleJasperReport(jasper)}
                              >
                                {jasper?.resultReportName}
                              </MenuItem>
                            ))}
                          </Menu>
                        )}
                      </div>
                    )}
                  {/* CHANGE TO EDIT BUTTON */}
                  {!isNil(formOperations) &&
                    !isEmpty(formOperations) &&
                    formOperations.indexOf(FORM_OPERATON_EDIT) > -1 &&
                    !isOutOperationOverride(FORM_OPERATON_EDIT) && (
                      <Tooltip title={t("TABLE_PANEL_DECISION_EDIT")}>
                        <button
                          className={
                            !isNil(actualOperation) &&
                            actualOperation === FORM_OPERATON_EDIT
                              ? classes.editButtonCommonSelected
                              : classes.editButtonCommon
                          }
                          onClick={() => changeOperation(FORM_OPERATON_EDIT)}
                        >
                          <Icon
                            className="fas fa-edit"
                            style={{
                              fontSize: "1.2em",
                              color:
                                !isNil(actualOperation) &&
                                actualOperation === FORM_OPERATON_EDIT
                                  ? "white"
                                  : theme.palette.content.mainColor,
                              width: "auto",
                            }}
                          />
                        </button>
                      </Tooltip>
                    )}
                  {/* CHANGE TO  DELETE BUTTON  */}
                  {!isNil(formOperations) &&
                    !isEmpty(formOperations) &&
                    formOperations.indexOf(FORM_OPERATION_DELETE) > -1 &&
                    !isOutOperationOverride(FORM_OPERATION_DELETE) && (
                      <Tooltip title={t("TABLE_PANEL_DECISION_DELETE")}>
                        <button
                          className={
                            !isNil(actualOperation) &&
                            actualOperation === FORM_OPERATION_DELETE
                              ? classes.deleteButtonCommonSelected
                              : classes.deleteButtonCommon
                          }
                          onClick={() => changeOperation(FORM_OPERATION_DELETE)}
                        >
                          <Icon
                            className="fas fa-trash-alt"
                            style={{
                              fontSize: "1.2em",
                              color:
                                !isNil(actualOperation) &&
                                actualOperation === FORM_OPERATION_DELETE
                                  ? "white"
                                  : "#f87979",
                              width: "auto",
                            }}
                          />
                        </button>
                      </Tooltip>
                    )}
                </div>
              ) : (
                <div> </div>
              )}
              {formSchema &&
              formSchema.name &&
              (isNil(hideTitle) || hideTitle === false) ? (
                <div className={classes.headerTitle}>
                  <Icon
                    className="fas fa-table"
                    style={{ ...commonIcon, marginRight: 3 }}
                  />
                  {IS_DEV && !isNil(formInstanceId) ? (
                    <Typography variant="h5" style={{ marginLeft: 5 }}>
                      {`${formSchema.name} #${formInstanceId.slice(-4)}`}
                    </Typography>
                  ) : (
                    <Typography variant="h5">{formSchema.name}</Typography>
                  )}
                </div>
              ) : (
                <div> </div>
              )}
              <HelpDialog
                hasHelp={formSchema?.hasHelp}
                path={path}
                idForm={idForm}
                nameForm={formSchema?.name}
              />
            </div>

            {/* CONTENT CONTAINER (TABS AND FORM)*/}
            <div className={classes.contentContainerColumn}>
              {/* TABS CONTAINER (LEFT SIDE,  VERTICAL MODE) */}
              <div className={classes.tabsContainer}>
                {!isNil(formSchema) &&
                  !isNil(formSchema.sections) &&
                  !isNil(sortedSections) &&
                  sortedSections.length > 0 && (
                    <Tabs
                      TabIndicatorProps={{
                        className: classes.tabIndicatorProps,
                      }}
                      orientation={"horizontal"}
                      value={selectedTab}
                      onChange={(event, newTab) => {
                        changeFormTab(event, newTab);
                        removeBadge({ formInstanceId });
                      }}
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="form-tabs"
                    >
                      {sortedSections.map((section) => {
                        return (
                          <Tab
                            key={section?.name}
                            label={
                              <BadgeWizardStepSection
                                shouldRenderBadge={shouldRenderBadge({
                                  formInstanceId,
                                  sectionName: section?.name,
                                })}
                              >
                                {IS_FORM_BUILDING_HELP_ENABLED
                                  ? `${section?.label} (${section.name})`
                                  : section?.label}
                              </BadgeWizardStepSection>
                            }
                            id={`base_form_container_tab_${section?.name}`}
                            classes={{
                              root: classes.tabRoot,
                              wrapper: classes.tabIconWrapper,
                              //labelContainer: classes.tabLabelContainer,
                              labelIcon: classes.tabLabelIcon,
                            }}
                            icon={
                              !isNil(section?.sectionIcon) ? (
                                <Icon
                                  className={section?.sectionIcon}
                                  style={{ marginBottom: 0 }}
                                />
                              ) : null
                            }
                          />
                        );
                      })}
                    </Tabs>
                  )}
              </div>

              {/* FORM CONTAINER - SizeME */}
              {!isNil(formSchema) && !isNil(formSchema.sections) && (
                <SizeMe on monitorHeight={true}>
                  {({ size }) => {
                    return (
                      <div
                        id="responsive-grid-lay-container"
                        //className={classes.formContainer}
                        style={{
                          width: "100%",
                          height: "100%",
                          maxHeight: `calc(100% - ${FOOTER_MIN_HEIGHT})`,
                        }}
                      >
                        <ResponsiveGridLayout
                          key={selectedTab}
                          width={Number.parseInt(size?.width)}
                          style={{
                            padding: 0,
                            height: Number.parseInt(size?.height),
                            overflowY: "auto",
                            position: "relative",
                            overflowX: "hidden",
                          }}
                          layouts={filteredGridLayoutItemsByVisibility[0]}
                          breakpoints={{
                            lg: BREAKPOINT_LG,
                            md: BREAKPOINT_MD,
                            sm: BREAKPOINT_SM,
                            xs: 0,
                          }}
                          cols={{
                            lg: 12,
                            md: 10,
                            sm: 4,
                            xs: 1,
                          }}
                          containerPadding={[5, 5]}
                          isDraggable={false}
                          isResizable={false}
                          margin={[5, 5]}
                          rowHeight={34}
                          onBreakpointChange={(nb) => {
                            changeLayoutBreakpoint(nb);
                          }}
                        >
                          {filter(
                            formSchema?.sections?.[selectedTab]?.blocks,
                            function (o) {
                              return includes(
                                filteredGridLayoutItemsByVisibility[1],
                                o?.id
                              );
                            }
                          ).map((block, index) => {
                            const {
                              id,
                              classStyles,
                              schema,
                              uiSchema,
                              blockType,
                              columns,
                              listName,
                              enableAddRow,
                              enableDeleteRow,
                              pre,
                              showSearchBarRow,
                              maxRows,
                              //isVisible,
                              denyAddRow,
                              disableMassiveGridLoad,
                              hideRadioButtonsGrid,
                            } = block;

                            const hasLegend =
                              !isNil(schema?.title) && !isEmpty(schema?.title);

                            // const isVisibleToUse = isBoolean(isVisible)
                            //   ? isVisible
                            //   : isString(isVisible)
                            //   ? toLower(
                            //       getValueInShadowStatus(isVisible, isVisible)
                            //     ) === "s"
                            //   : true;

                            const wizardConfig = formSchema?.wizard;

                            const isBlockDisabledByWizard = !isNil(wizardConfig)
                              ? includes(arrayBlocksDisabledByWizard, id)
                              : false;

                            return (
                              <div key={id} className={classStyles}>
                                <BlockUi
                                  blocking={isSettingValueInServer || fetching}
                                  style={{
                                    width: "100%",
                                    height: hasLegend
                                      ? "100%"
                                      : "calc(100% - 1.5px)",
                                  }}
                                  tag="div"
                                >
                                  {blockType === BLOCK_TYPE_SIMPLE ||
                                  isNil(blockType) ? (
                                    <SimpleBlock
                                      {...block}
                                      pre={pre}
                                      hasLegend={hasLegend}
                                      shadowStatus={shadowStatus}
                                      wizardConfig={wizardConfig}
                                      formSchema={formSchema}
                                      selectedTab={selectedTab}
                                      moveToAnotherSectionByIndex={
                                        moveToAnotherSectionByIndex
                                      }
                                      formInstanceId={formInstanceId}
                                      isListBlock={false}
                                      operation={actualOperation}
                                    >
                                      <Form
                                        key={`${id}_${index}`}
                                        fields={customSimpleFields}
                                        formContext={{
                                          globalFormData: formData,
                                          syncErrorMap,
                                          errorMap,
                                          operation: !isNil(actualOperation)
                                            ? actualOperation
                                            : operation,
                                          initialFormData,
                                          section:
                                            formSchema.sections[selectedTab],
                                          block: id,
                                          formInstanceId,
                                          handleFormDataChange,
                                          notifySetValueToServer,
                                          formWithErrors:
                                            errorMap && errorMap.length > 0,
                                          pre,
                                          shadowStatus,
                                          transformStatusAndSetInState,
                                          showSearchBarRow,
                                          updateShadowStatus,
                                          wizardConfig,
                                          isBlockDisabledByWizard,
                                        }}
                                        formData={formData}
                                        id={makeUniqueFormID(id)}
                                        registry={{
                                          fields: customSimpleFields,
                                        }}
                                        schema={
                                          IS_FORM_BUILDING_HELP_ENABLED
                                            ? {
                                                ...schema,
                                                title: `${resolveTitleBlockWithFormStatus(
                                                  schema.title
                                                )} (${id})`,
                                              }
                                            : {
                                                ...schema,
                                                title: `${resolveTitleBlockWithFormStatus(
                                                  schema.title
                                                )}`,
                                              }
                                        }
                                        uiSchema={
                                          !isNil(uiSchema) ? uiSchema : {}
                                        }
                                        onChange={(e) =>
                                          handleFormDataChange(e.formData)
                                        }
                                        autoComplete="false"
                                        liveValidate={false}
                                        noHtml5Validate={true}
                                        noValidate={true}
                                      />
                                    </SimpleBlock>
                                  ) : blockType === BLOCK_TYPE_LIST ? (
                                    <SimpleBlock
                                      {...block}
                                      pre={pre}
                                      key={`${id}_${schema?.title}_block`}
                                      hasLegend={
                                        !isNil(schema?.title) &&
                                        !isEmpty(schema?.title)
                                      }
                                      shadowStatus={shadowStatus}
                                      wizardConfig={wizardConfig}
                                      formSchema={formSchema}
                                      selectedTab={selectedTab}
                                      moveToAnotherSectionByIndex={
                                        moveToAnotherSectionByIndex
                                      }
                                      formInstanceId={formInstanceId}
                                      isListBlock={true}
                                      operation={actualOperation}
                                    >
                                      <form
                                        key={`${id}_${schema?.title}`}
                                        style={{
                                          width: "100%",
                                          height: "100%",
                                          overflowX: "auto",
                                        }}
                                      >
                                        <fieldset id={id}>
                                          <legend>
                                            {resolveTitleBlockWithFormStatus(
                                              schema?.title
                                            )}
                                          </legend>
                                          <GridTable
                                            columns={columns}
                                            dataInTable={
                                              !isNil(formData) &&
                                              !isNil(formData[listName])
                                                ? formData[listName]
                                                : []
                                            }
                                            formContext={{
                                              formData,
                                              setFormData,
                                              syncErrorMap,
                                              errorMap,
                                              operation: !isNil(actualOperation)
                                                ? actualOperation
                                                : operation,
                                              initialFormData,
                                              section:
                                                formSchema.sections[
                                                  selectedTab
                                                ],
                                              block: id,
                                              formInstanceId,
                                              listName,
                                              enableAddRow,
                                              enableDeleteRow,
                                              formWithErrors:
                                                errorMap && errorMap.length > 0,
                                              toggleIsSettingValueToServer,
                                              shadowStatus,
                                              updateShadowStatus,
                                              transformStatusAndSetInState,
                                              showSearchBarRow,
                                              maxRows,
                                              denyAddRow,
                                              disableMassiveGridLoad,
                                              hideRadioButtonsGrid,
                                              formSchema,
                                              isBlockDisabledByWizard,
                                            }}
                                            formData={formData}
                                            handleFormDataChange={
                                              handleFormDataChange
                                            }
                                            name={listName}
                                            schema={schema}
                                            theme={theme}
                                            title={""}
                                            toastId={toastId}
                                          />
                                        </fieldset>
                                      </form>
                                    </SimpleBlock>
                                  ) : (
                                    <BadForm key={`${id}_${index}`} />
                                  )}
                                </BlockUi>
                              </div>
                            );
                          })}
                        </ResponsiveGridLayout>
                      </div>
                    );
                  }}
                </SizeMe>
              )}
            </div>

            {/* FOOTER DECISION BUTTON CONTAINER */}
            <div className={classes.footer}>
              {formInstanceId && showTrazability ? (
                <Tooltip title="Services and Graph Trazability">
                  <IconButton
                    aria-label="toggle tracking service dialog"
                    className={classes.iconButtonOpenTrazability}
                    id={"form_container_trazability_button"}
                    onClick={() => handleTSDialog(!openTSDialog)}
                  >
                    <Icon
                      className="fas fa-business-time"
                      style={{
                        ...commonIcon,
                        width: "auto",
                        height: "auto",
                        padding: 1,
                        fontSize: "1rem",
                      }}
                    />
                  </IconButton>
                </Tooltip>
              ) : (
                <div />
              )}
              {/* END BUTTON GROUP  (SAVE, CANCEL, BACK)*/}
              <div className={classes.footerEndButtonGroup}>
                {/* SAVE BUTTON WITH TOOLTIP AND BADGE FOR ERRORS */}
                {!isNil(formSchema) &&
                  actualOperation !== FORM_OPERATION_VIEW && (
                    // <Tooltip title={errorListToShow}>
                    //   <Badge badgeContent={errorMap.length} color="error">
                    <button
                      className={classes.decisionButtonForm}
                      disabled={
                        fetching === true ||
                        alreadyDone === true ||
                        isSettingValueInServer === true ||
                        isNil(formOperations) ||
                        (!isNil(formOperations) && isEmpty(formOperations)) ||
                        (!isNil(formOperations) &&
                          !includes(formOperations, operation)) ||
                        (!isNil(formOperations) &&
                          !includes(formOperations, actualOperation))
                      }
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleExecuteAction();
                      }}
                      id={`${EXERNAL_ID_FORM_CONTAINER_ACTION_BUTTON}-${formInstanceId}`}
                    >
                      <Icon
                        className={getFasIcon()}
                        style={{
                          fontSize: "1.2em",
                          marginRight: "0.25em",
                          color: theme.palette.content.mainColor,
                        }}
                      />
                      <strong>{getDescText()}</strong>
                    </button>
                    //   </Badge>
                    // </Tooltip>
                  )}
                <DeleteFormConfirmDialog
                  confirm={(confirmed) => {
                    setDeleteFormConfirmation(false);
                    if (confirmed) {
                      executeAction();
                    }
                  }}
                  open={deleteFormConfirmation}
                />
                <Divider flexItem orientation="vertical" />
                <button
                  className={classes.decisionButtonForm}
                  disabled={fetching === true || alreadyDone === true}
                  onClick={() => handleBackClick()}
                >
                  <Icon
                    className="fas fa-arrow-circle-left"
                    style={{
                      fontSize: "1.2em",
                      marginRight:
                        wd && wd.width && wd.width > BREAKPOINT_SM && "0.25em",
                      color: theme.palette.content.mainColorContrastText,
                    }}
                  />
                  {Boolean(wd && wd.width && wd.width > BREAKPOINT_SM) && (
                    <p>{t("FORM_BACK")}</p>
                  )}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
}
