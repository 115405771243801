import React, {
  useState,
  useEffect,
  useRef,
  useMemo,
} from "react";
import * as _ from "lodash";

import ContextMenu from "../../handlers/HandlerContextMenu";
import {
  generateRandomID,
  getInputType,
  getSelectionServices,
  defineInputEnabledByOperation,
  getCompletePath,
  returnFocusToRef,
  isServerResponseValid,
  extractErrorMessageFromResponse,
  findTopLeft,
  callServerToSetMultipleValues,
  obtainPositionOfEvent,
  getDisabledSelectionServicesByDisabledInteractionProp,
  checkIsPkControl,
} from "../../../../util/UtilForm";
import { KEY_F1, KEY_TAB } from "../../../../util/UtilKeys";
import {
  getTransformedTextByDef,
  isMaxLengthReached,
} from "../../../../util/UtilFormat";
import { FORM_INPUT_TEXT, TOAST_TYPE_ERROR } from "../../../../util/Constants";
import SimpleFieldContainer from "../container/SimpleFieldContainer";
import {
  checkTypeOfElementById,
  ELEMENT_TYPE_BTN,
  ELEMENT_TYPE_TEXT,
} from "../../../../util/util-io";
import { ID_FORM_CONTAINER_ACTION_BUTTON } from "../../formContainer/FormContainer";
import NormalInput from "./NormalInput";
import { useRequestHeaders } from "../../../../core/hooks/useRequestHeaders";
import HelperInput from "./HelperInput";
import { useBlockingToast } from "../../../../core/hooks/useBlockingToast";
import InteractiveInputButton from "./InteractiveInputButton";
import { EXERNAL_ID_FORM_CONTAINER_ACTION_BUTTON } from "../../externalFormContainer/BaseForm";

export default function SimpleInputField(props) {
  const { idSchema, schema, formData, uiSchema, formContext, name } = props;
  const {
    value: inputValue,
    presentationInfo: pi,
    isSavedEntity,
  } = formData || {};
  const inputRef = useRef(null);
  const [openMenu, setOpenMenu] = useState(false);
  const [inputEnabled, setInputEnabled] = useState(true);
  const [comeFromSelection, setComeFromSelection] = useState(false);
  const [menuPosition, setMenuPosition] = useState({ x: null, y: null });
  const [presentationInfo, setPresentationInfo] = useState(null);
  const [lastWasTab, setLastWasTab] = useState(false);

  const {
    operation,
    section,
    block,
    formInstanceId,
    handleFormDataChange,
    globalFormData,
    notifySetValueToServer,
    isSettingValueInServer,
    pre,
    transformStatusAndSetInState,
    shadowStatus,
    isBlockDisabledByWizard,
  } = formContext;

  //Get Request headers
  const REQUEST_HEADERS = useRequestHeaders();
  const { renderBlockingToast, BlockingToastModal } = useBlockingToast();

  //Complete id of the field that i stand, #G.G1.countryName
  let completeIdControl = getCompletePath(
    section,
    block,
    idSchema?.$id,
    schema
  );

  const isPkControl = checkIsPkControl({ completeIdControl });

  //Generates UNIQUE ID
  const generatedID = generateRandomID(idSchema?.$id);

  //GET SELECTION SERVICES
  let selectionServices = getSelectionServices(schema);

  //Get Helper Def from schema property configuration
  const helperDef = schema?.helperDef;

  //Get Interactive Btn Def from schema property configuration
  const interactiveBtnDef = schema?.interactiveBtnDef;

  //IMPORTANT check if input es enabled by operation and props canEdit and canNew
  useEffect(() => {
    if (schema && schema?.readOnly === true) {
      setInputEnabled(false);
    } else {
      const editableByPk = isPkControl === true ? false : schema?.canEdit;
      const checkInputEnabled = defineInputEnabledByOperation(
        operation,
        editableByPk,
        schema?.canNew
      );
      if (!_.isNil(checkInputEnabled)) {
        setInputEnabled(checkInputEnabled);
      }
    }
  }, [isPkControl, operation, schema]);

  //Presentation info reset handling
  useEffect(() => {
    if (!_.isNil(pi) && !_.isNil(pi.reset)) {
      const piToSet = pi.reset ? null : pi;
      setPresentationInfo(piToSet);
    }
  }, [pi]);

  //Handle context menu opening and position(x and y)
  const handleContextMenu = (e, isRightClick) => {
    //Prevent default and show context menu only if input is enabled
    if (inputEnabled && !isRightClick) {
      e.preventDefault();
      const cliX = e.clientX;
      const cliY = e.clientY;
      if (cliX > 0 && cliY > 0)
        setMenuPosition({
          x: cliX,
          y: cliY,
        });
      setOpenMenu(true);
    }
  };

  //Handle F1 press to Open Context Menu
  const handleKeyPress = (e) => {
    if (e.keyCode === KEY_F1 && openMenu === false) {
      e.preventDefault();
      const coords = findTopLeft(`${e?.target?.id}`);
      if (!_.isNil(coords) && !_.isNil(coords.top) && !_.isNil(coords.left)) {
        setOpenMenu(true);
        setMenuPosition({
          x: coords.left,
          y: coords.top,
        });
      }
    } else if (e.keyCode === KEY_TAB || e.which === KEY_TAB) {
      setLastWasTab(true);
    }
  };

  const handleLostInputFocusHelper = async (e) => {
    return await handleLostInputFocus(e, inputValue);
  };

  //In charge of SET VALUE IN SERVER AFTER INPUT FOCUS IS LOST
  const handleLostInputFocus = async (e, currentValue = undefined) => {
    let mustAddNewRow = false;
    let idOfButton = null;
    let idOfTextField = null;
    let isActionButton = true;

    const fieldValue =
      currentValue && currentValue !== "" ? currentValue : null;

    if (!_.isNil(e.relatedTarget)) {
      const relatedTarget = e.relatedTarget;

      const targetAttrType =
        relatedTarget.getAttribute("type") || relatedTarget.getAttribute("id");

      if (targetAttrType === "button" || targetAttrType === "checkbox") {
        e.preventDefault();
        e.stopPropagation();
        mustAddNewRow = true;
        idOfButton = relatedTarget.getAttribute("id");
      } else if (targetAttrType === ID_FORM_CONTAINER_ACTION_BUTTON) {
        e.preventDefault();
        e.stopPropagation();
        mustAddNewRow = true;
        idOfButton = targetAttrType;
        isActionButton = true;
      } else if (
        targetAttrType ===
        `${EXERNAL_ID_FORM_CONTAINER_ACTION_BUTTON}-${formInstanceId}`
      ) {
        e.preventDefault();
        e.stopPropagation();
        mustAddNewRow = true;
        idOfButton = targetAttrType;
        isActionButton = true;
      } else if (targetAttrType === "text") {
        e.preventDefault();
        e.stopPropagation();
        idOfTextField = relatedTarget.getAttribute("id");
      }

      let returnFocusToInput = false;
      if (!_.isNil(returnFocusToInput) && returnFocusToInput === true) {
        returnFocusToRef(inputRef);
      } else {
        await notifySetValueToServer(
          formInstanceId,
          completeIdControl,
          fieldValue,
          0,
          inputRef
        );

        if (checkTypeOfElementById(idOfTextField, ELEMENT_TYPE_TEXT) === true) {
          document.getElementById(idOfTextField).focus();
        }
        if (
          mustAddNewRow &&
          (checkTypeOfElementById(idOfButton, ELEMENT_TYPE_BTN) === true ||
            isActionButton)
        ) {
          if (lastWasTab) {
            document.getElementById(idOfButton).focus();
            setLastWasTab(false);
          } else {
            if (!isActionButton) {
              let posOfButton = obtainPositionOfEvent(idOfButton);
              setMenuPosition({
                x: posOfButton.clientX,
                y: posOfButton.clientY,
              });
            }
            if (document && document.getElementById(idOfButton)) {
              document.getElementById(idOfButton).click();
            }
          }
        }
      }
    } else {
      await notifySetValueToServer(
        formInstanceId,
        completeIdControl,
        fieldValue,
        0,
        inputRef
      );
    }
  };

  //Handle events WHEN FOCUS
  const hanleInputFocus = (e) => {
    //If i come from a data selection then make validations
    if (comeFromSelection === true) {
      //makeClientValidations(true);
    }
  };

  //Changes the value written into the state and the form data booooom --> SET FORM DATA
  const changeValueFormContextMenu = async (valuesFromSelection) => {
    //Call service to set multiple values
    const response = await callServerToSetMultipleValues(
      {
        formInstanceId,
        values: valuesFromSelection,
      },
      REQUEST_HEADERS
    );

    //Analyze response
    if (isServerResponseValid(response)) {
      transformStatusAndSetInState(response, completeIdControl);

      // const { fstatus } = response;
      // const transformedFormStatus = transformFormStatusFromServer(fstatus);
      // const newGlobalData = { ...globalFormData, ...transformedFormStatus };
      // //Set new form data through form context
      // await handleFormDataChange(newGlobalData);
    } else if (
      !_.isNil(response) &&
      !_.isNil(response.error) &&
      response.error === true &&
      !_.isNil(response.msg)
    ) {
      //Extract error message if there is one
      const toastText = extractErrorMessageFromResponse(response);

      //Throw toast
      // toast.error(toastText, {
      //   containerId: TOAST_CONTAINER_LAYOUT,
      //   toastId: completeIdControl,
      //   position: "top-right",
      //   autoClose: false,
      //   hideProgressBar: false,
      //   closeOnClick: false,
      //   pauseOnHover: true,
      //   draggable: true,
      //   progress: undefined,
      // });

      renderBlockingToast({
        type: TOAST_TYPE_ERROR,
        title: toastText,
      });
    }
  };

  const handleValueTyping = (value) => {
    let typedValue = _.clone(value);

    if (isMaxLengthReached(typedValue, schema)) return;

    const inputType = getInputType(schema?.type);
    if (inputType && inputType === FORM_INPUT_TEXT) {
      typedValue = getTransformedTextByDef(typedValue, schema);
    }

    //Gets accessor
    const accessor =
      schema && schema.entityParent ? `${schema.entityParent}.${name}` : name;

    //VERY IMPORTANTE LINE, form context has a bug in development/debug mode
    const prevoiusStateToSet = globalFormData;

    if (!_.isNil(typedValue) && typedValue !== "") {
      //Making new object with name as key and typed value as value to set
      let obj = { value: typedValue, presentationInfo };

      //Set new form data through form context
      const newFormData = _.clone(prevoiusStateToSet);
      _.set(newFormData, accessor, obj);

      //Setting GLOBAL form data
      handleFormDataChange(newFormData);
    } else if (!_.isNil(typedValue) && typedValue === "") {
      //Check if prev state is valid
      if (!_.isNil(prevoiusStateToSet)) {
        const fieldControl = _.get(prevoiusStateToSet, accessor);
        if (fieldControl) {
          fieldControl.value = typedValue;

          const newFormData = { ...prevoiusStateToSet };
          _.set(newFormData, accessor, fieldControl);

          //Setting GLOBAL form data
          handleFormDataChange(newFormData);
        }
      }
    }
  };

  //Close context menu
  const closeMenu = () => {
    setOpenMenu(false);
  };

  function showInput(schema) {
    if (schema && schema.onlyLabel) {
      return false;
    } else {
      return true;
    }
  }

  const disabledSelectionServicesDynamic = useMemo(() => {
    return getDisabledSelectionServicesByDisabledInteractionProp({
      selectionServices,
      shadowStatus,
    });
  }, [selectionServices, shadowStatus]);

  const isInputEnabledInWizard =
    _.isNil(isBlockDisabledByWizard) || isBlockDisabledByWizard === false;

  return (
    <SimpleFieldContainer
      schema={schema}
      completeIdControl={completeIdControl}
      pre={pre}
      presentationInfo={presentationInfo}
    >
      <BlockingToastModal />
      <div style={{ width: "100%" }}>
        {/* CONTEXT MENU FOR SELECTION SERVICES */}
        {!_.isNil(selectionServices) &&
          !_.isEmpty(selectionServices) &&
          !_.isNil(menuPosition) &&
          openMenu === true && (
            <ContextMenu
              openMenu={openMenu}
              uniqueID={generatedID}
              services={selectionServices}
              menuPosition={menuPosition}
              setValue={changeValueFormContextMenu}
              closeMenu={closeMenu}
              formContextData={globalFormData}
              setComeFromSelection={setComeFromSelection}
              line={0}
              formInstanceId={formInstanceId}
              auto={selectionServices.length === 1}
              interactiveBtnDef={interactiveBtnDef}
              shadowStatus={shadowStatus}
              disabledSelectionServicesDynamic={
                disabledSelectionServicesDynamic
              }
            />
          )}
        {showInput(schema) === true &&
          (_.isNil(helperDef) || !helperDef?.enabled) &&
          _.isNil(interactiveBtnDef) && (
            <NormalInput
              completeIdControl={completeIdControl}
              presentationInfo={presentationInfo}
              schema={schema}
              uiSchema={uiSchema}
              operation={operation}
              isSavedEntity={isSavedEntity}
              inputEnabled={inputEnabled && isInputEnabledInWizard}
              inputValue={inputValue}
              inputRef={inputRef}
              isSettingValueInServer={isSettingValueInServer}
              selectionServices={selectionServices}
              handleContextMenu={handleContextMenu}
              handleKeyPress={handleKeyPress}
              hanleInputFocus={hanleInputFocus}
              handleLostInputFocus={handleLostInputFocus}
              handleValueTyping={handleValueTyping}
              disabledSelectionServicesDynamic={
                disabledSelectionServicesDynamic
              }
            />
          )}

        {showInput(schema) === true &&
          !_.isNil(helperDef) &&
          helperDef?.enabled && (
            <HelperInput
              completeIdControl={completeIdControl}
              presentationInfo={presentationInfo}
              schema={schema}
              uiSchema={uiSchema}
              operation={operation}
              isSavedEntity={isSavedEntity}
              inputEnabled={inputEnabled && isInputEnabledInWizard}
              inputValue={inputValue}
              inputRef={inputRef}
              isSettingValueInServer={isSettingValueInServer}
              selectionServices={selectionServices}
              handleContextMenu={handleContextMenu}
              handleKeyPress={handleKeyPress}
              hanleInputFocus={hanleInputFocus}
              handleLostInputFocus={handleLostInputFocusHelper}
              handleValueTyping={handleValueTyping}
              comeFromSelection={comeFromSelection}
              changeValueFormContextMenu={changeValueFormContextMenu}
              helperDef={helperDef}
              formInstanceId={formInstanceId}
              disabledSelectionServicesDynamic={
                disabledSelectionServicesDynamic
              }
            />
          )}

        {showInput(schema) === true &&
          (_.isNil(helperDef) || !helperDef?.enabled) &&
          !_.isNil(interactiveBtnDef) && (
            <InteractiveInputButton
              completeIdControl={completeIdControl}
              presentationInfo={presentationInfo}
              schema={schema}
              uiSchema={uiSchema}
              operation={operation}
              isSavedEntity={isSavedEntity}
              inputEnabled={inputEnabled && isInputEnabledInWizard}
              inputValue={inputValue}
              inputRef={inputRef}
              isSettingValueInServer={isSettingValueInServer}
              selectionServices={selectionServices}
              handleContextMenu={handleContextMenu}
              handleKeyPress={handleKeyPress}
              hanleInputFocus={hanleInputFocus}
              handleLostInputFocus={handleLostInputFocus}
              handleValueTyping={handleValueTyping}
              disabledSelectionServicesDynamic={
                disabledSelectionServicesDynamic
              }
            />
          )}
      </div>
    </SimpleFieldContainer>
  );
}
