import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import useInfo from "../../store/hooks/useInfo";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Icon,
  List,
  ListItem,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { isNil } from "lodash";

const useStyles = makeStyles(() => ({
  bodySubtitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 6,
    flexDirection: "row",
  },
  actionBtn: {
    textTransform: "none",
  },
  emsLogoIcon: {
    width: 30,
  },
}));

const commonIconStyles = {
  width: "auto",
  height: "auto",
  padding: 1,
};

const YES_LIST = [
  "EMPLOYEE_STORE.DIALOG_EMS_CLOSED_YES_LIST_0",
  "EMPLOYEE_STORE.DIALOG_EMS_CLOSED_YES_LIST_1",
  "EMPLOYEE_STORE.DIALOG_EMS_CLOSED_YES_LIST_2",
];

const NO_LIST = [
  "EMPLOYEE_STORE.DIALOG_EMS_CLOSED_NO_LIST_0",
  "EMPLOYEE_STORE.DIALOG_EMS_CLOSED_NO_LIST_1",
  "EMPLOYEE_STORE.DIALOG_EMS_CLOSED_NO_LIST_2",
];

export default function EmployeeStoreLayoutEmsClosedDialog() {
  const { t } = useTranslation();
  const classes = useStyles();
  const {
    initialInfo,
    firstInitialInfoCompleted,
    showClosedEmsDialog,
    closeClosedEmsDialog,
  } = useInfo();

  const hasToOpen = useMemo(() => {
    return (
      !isNil(initialInfo) &&
      firstInitialInfoCompleted === true &&
      showClosedEmsDialog
    );
  }, [firstInitialInfoCompleted, initialInfo, showClosedEmsDialog]);

  return (
    <Dialog open={hasToOpen} maxWidth="lg">
      <DialogContent>
        <DialogContentText variant="h4">
          {t("EMPLOYEE_STORE.DIALOG_EMS_CLOSED_TITLE")}
        </DialogContentText>
        <Box className={classes.bodySubtitle}>
          <Icon
            className={"fas fa-check-circle"}
            style={{ ...commonIconStyles, color: "#17a589" }}
          />
          <Typography variant="h6">
            {t("EMPLOYEE_STORE.DIALOG_EMS_CLOSED_BODY_YES")}
          </Typography>
        </Box>

        <List dense={true}>
          {YES_LIST.map((x) => {
            return (
              <ListItem key={x}>
                <ListItemText primary={`• ${t(x)}`} />
              </ListItem>
            );
          })}
        </List>
        <Box className={classes.bodySubtitle}>
          <Icon
            className={"fas fa-times-circle"}
            style={{ ...commonIconStyles, color: "#d11d46" }}
          />
          <Typography variant="h6">
            {t("EMPLOYEE_STORE.DIALOG_EMS_CLOSED_BODY_NO")}
          </Typography>
        </Box>
        <List dense={true}>
          {NO_LIST.map((x) => {
            return (
              <ListItem key={x}>
                <ListItemText primary={`• ${t(x)}`} />
              </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={closeClosedEmsDialog}
          color="primary"
          className={classes.actionBtn}
          endIcon={
            <img
              src={`${process.env.PUBLIC_URL}/assets/img/employee-store/employee-store-icon-light.webp`}
              alt="store-logo"
              className={classes.emsLogoIcon}
            />
          }
        >
          {`${t("EMPLOYEE_STORE.DIALOG_EMS_CLOSED_ACTION")} ${t(
            "EMPLOYEE_STORE.DISPLAY_NAME"
          )}`}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
